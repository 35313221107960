import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [

// die richtigen pools für meinen blast MC

{
  pid: 0,  // für weth preis berechung
  risk: 1,
  lpSymbol: 'WETH - USDB',  
  lpAddresses: {
    81457: '0xBcc283A3F2768F11B99D8EaaF1AD5eac456a3f76',
    97: '',
    56: '',    
  },
  tokenSymbol: 'WETH',
  tokenAddresses: {
    81457: '0x4300000000000000000000000000000000000004',
    97: '',
    56: '',
  },
  quoteTokenSymbol: QuoteToken.BUSD,
  quoteTokenAdresses: contracts.busd,
},

{
pid: 1,
risk: 1,
lpSymbol: 'BTC - USDB',  
lpAddresses: {
  81457: '0x6266dB4DbA92113CbF191DB9C5789Fe93E8fD33f',
  97: '',
  56: '',    
},
tokenSymbol: 'BTC',
tokenAddresses: {
  81457: '0xF7bc58b8D8f97ADC129cfC4c9f45Ce3C0E1D2692',
  97: '',
  56: '',
},
quoteTokenSymbol: QuoteToken.BUSD,
quoteTokenAdresses: contracts.busd,
},

{
pid: 2,
risk: 1,
lpSymbol: 'WETH - GMT',   // für token preis berechnung, später pid in hooks auf richtige tauschen
lpAddresses: {
  81457: '0x135E51Aac2e05fC8a2e485AF0E07f4E3CCfCEdf6',
  97: '',
  56: '',   
},
tokenSymbol: 'GMT',
tokenAddresses: {
  81457: '0xB60dF4A1db61B173d0d774ffa813C3c37070E38b',
  97: '',
  56: '',
},
quoteTokenSymbol: QuoteToken.BNB,
quoteTokenAdresses: contracts.wbnb,
},

// Single Token/Asset Pools (are shown in /pools)
  
{
  pid: 3,
  risk: 1,
  isTokenOnly: true,
  lpSymbol: 'WETH',  
  lpAddresses: {
    81457: '0xBcc283A3F2768F11B99D8EaaF1AD5eac456a3f76',  // weth USDB LP
    97: '',
    56: '',    
  },
  tokenSymbol: 'BNB',
  tokenAddresses: {
    81457: '0x4300000000000000000000000000000000000004',
    97: '',
    56: '',
  },
  quoteTokenSymbol: QuoteToken.BUSD,
  quoteTokenAdresses: contracts.busd,
},

{
  pid: 4,
  risk: 1,
  isTokenOnly: true,
  lpSymbol: 'USDB',  
  lpAddresses: {
    81457: '0x4300000000000000000000000000000000000003',
    97: '',
    56: '',    
  },
  tokenSymbol: 'USDB',
  tokenAddresses: {
    81457: '0x4300000000000000000000000000000000000003',
    97: '',
    56: '',
  },
  quoteTokenSymbol: QuoteToken.BUSD,
  quoteTokenAdresses: contracts.busd,
},


]

export default farms